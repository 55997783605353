/* --- Colors  ---*/
$white:             #fff;
$bright-grey:       #cfd9e4;
$dark-grey:         #3a5169;
$green:             #16A084;
$purple:            #735093;

$red:               #FF5252;
$orange:            #F7B245;
$yellow:            #F0CB35;
$bright-green:      #2BB164;
$bright-blue:       #2988C7;


$primary-color:     $green;
$secondary-color:   $dark-grey;

$text-color: #161e27;
$accent-color: lighten($primary-color, 10%);
$bg-color: $bright-grey;
$link-color: $secondary-color;
$selection-color: darken($secondary-color, 10%);

$active-color: lighten($purple, 17%);
$active-color-light: lighten($purple, 35%);

$min-width-xs: 576px;
$min-width-md: 768px;
$min-width-lg: 992px;
$min-width-xl: 1200px;