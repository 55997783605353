// Global Styles
@import 'variables';
// Webfont
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i');
// PrimeNG Overwrites
@import 'primeng-overwrites';
// ngx-toastr
@import 'ngx-toastr/toastr';

// reduce responsiveness
.affix {
	position: unset !important;
}

body {
	min-width: 1400px !important;
}

.container {
    width: inherit !important;
}

textarea {
    max-width: 100%;
}

html,
body {
    height: 100%;
    padding-bottom: 60px;
}

// General
body {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-top: 60px;
    background-color: $bright-grey;
}

h2 {
    margin: 2em 0 0;
    font-size: 1.5em;
    color: $secondary-color;
    text-transform: uppercase;
}

.dark-text {
    color: $purple !important;
}

.container-light-gray {
    background-color: lighten($bright-grey, 5%);
    margin: 1em 20px 0 20px;
    padding-bottom: 20px;
}

.container-lighter-gray {
    background-color: lighten($bright-grey, 10%);
    margin: 0;
    padding: 1em;
}

.black-link,
.black-link:visited,
.black-link:hover {
    color: black;
}

.gray-link,
.gray-link:visited,
.gray-link:hover {
    color: $dark-grey;
}

// Font-Awesome Icons
.fa-gray {
    color: $dark-grey;
}

// Webkit Scrollbar
*::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.08);
}

.input-error {
    background: lighten($red, 10%);
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 0 0 3px 3px;
    line-height: 1;
    padding: 6px 10px;
    margin-top: -1px;
}

// Bootstrap Classes
.progress {
    border-radius: 0;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: $dark-grey;
    border-color: $dark-grey;
}

.input-xs {
    height: 22px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

// Media Queries
@media (min-width: 1200px) {
    .container {
        max-width: 1600px;
        width: auto;
    }
}

// Remove Arrows on number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

// Styles for printing or hiding elements from printing
@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
    body {
        padding: 0;
    }
}

.reference-link {
    color: darkslategray;
    cursor: help;
    text-decoration: underline;
    text-decoration-style: dotted;
}

.top-bar {
    display: flex;
    align-items: baseline;

    & > i {
        margin-left: auto;
        margin-right: 5px;
    }
}

// spacing
.padding-40 {
    padding: 40px;
}

.padding-l-20 {
    padding-left: 20px;
}

.padding-b-20 {
    padding-bottom: 20px;
}

.margin-b-20 {
    margin-bottom: 20px;
}

.margin-b-0 {
    margin-bottom: 0px;
}

.margin-t-0 {
    margin-top: 0px;
}

.margin-t-20 {
    margin-top: 20px;
}

.margin-t-5 {
    margin-top: 5px;
}

.margin-l-20 {
    margin-left: 20px;
}

.padding-l-5 {
    padding-left: 5px;
}

.padding-r-5 {
    padding-right: 5px;
}

.margin-r-5 {
    margin-right: 5px;
}

.margin-r-0 {
    margin-right: 0 !important;
}

.margin-l-5 {
    margin-left: 5px;
}

.w-100 {
    width: 100%;
}

// use word break with auto hypen on all tables
td {
    word-wrap: break-word;
    overflow-wrap: break-word;

  -webkit-hyphens: auto;
     -moz-hyphens: auto;
          hyphens: auto;
}

// override active color inputs
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus
{
    border-color: $purple !important;
    outline: 0 !important;;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(115 80 147 / 60%) !important;
}

select:active, select:hover {
    outline:none;
    border: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(115 80 147 / 60%) !important;
}

.flex-bottom {
    display: flex;
    align-items: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}


// select button bootstrap
.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover {
    background: $purple;
    border-color: $purple;
    color: $white;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: unset;
}

// override for dialog in kontakt-edit component
#abacusContactDialog .p-dialog-content {
    overflow: visible;
}

.ngx-file-drop-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    color: $text-color;
}

.ngx-file-drop-zone {
    height: 100px;
    margin: auto;
    border: 2px dotted $text-color;
    border-radius: 30px;
}
