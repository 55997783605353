@import 'variables';

table {
    th {
        background: $dark-grey !important;
        color: white !important;
        padding: 5px !important;
    }
    tr {
        border: 1px solid #d3d3d3 !important;
    }

    td {
        padding: 5px !important;
        padding: 0.571em 0.857em !important;
    }
}

body .p-datatable .p-datatable-caption,
body .p-datatable .p-datatable-header {
    background: $dark-grey !important;
    color: white !important;
    border: #c8c8c8;
}

body .p-datatable .p-datatable-thead > tr > th {
    background: $dark-grey !important;
    color: white !important;
}

// Hovering over Table Headers
body .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: $dark-grey !important;
    color: white !important;
}

// Selecting Table Headers
body .p-datatable .p-sortable-column.p-highlight {
    background: $dark-grey !important;
    color: white !important;
}

// Table sorting arrows
body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: white !important;
}

// Table active rows
body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: lighten($purple, 15%) !important;
}


body .p-datatable .p-datatable-tbody > .ng-star-inserted:nth-child(even) {
    background-color:  #f9f9f9;
}

// Table hovering over rows
.table-hover {
    tbody {
        tr:hover {
            background-color: lighten($purple, 35%) !important;
        }
    }
}

.selectable.p-selectable-row.ng-star-inserted:not(.p-highlight):focus {
    outline: $dark-grey auto 1px;
    outline-color: $dark-grey;
    outline-style: auto;
    outline-width: 1px;
}

.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background-color: unset;
}

.p-multiselect .p-multiselect-label {
    color: #333333;
}

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover,
body .p-datatable tr.p-selectable-row:not(.p-highlight):not(.p-contextmenu-selected):hover {
    background: lighten($purple, 35%) !important;
}

// Paginator
body .p-paginator {
    background: white !important;
    height: auto;
}

.p-datatable .p-paginator-bottom {
    border-top: 1px solid #d3d3d3 !important;
}

body .p-paginator .p-paginator-pages .p-paginator-element {
    height: 2.286em !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: lighten($purple, 15%) !important;
}

// Panel Headings
.panel-title {
    color: white !important;
    font-weight: bold !important;
    font-size: 1em !important;
}

// Multi-Selection Table
body .p-chkbox .p-chkbox-box.p-active {
    border: 1px solid $active-color !important;
    background-color: $active-color !important;
    color: $white !important;
}

// Buttons
body .p-button.p-button-text-icon-left .p-button-text {
    background: $dark-grey !important;
    color: white !important;
}

body .p-togglebutton {
    color: white !important;
    background: #3a5169 !important;
    padding: 0.429em 1em 0.429em 2em !important;
}



// Select-Buttons {
body .p-selectbutton .p-button.p-active:not(.p-disabled) {
    background: $dark-grey !important;
    color: white !important;
    &:hover {
        background: $dark-grey !important;
        color: white !important;
    }
}

// Panels
.panel-default > .panel-heading {
    background-color: $dark-grey !important;
    color: $bright-grey !important;
}

// Produkte Chips
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
    background: lighten($purple, 15%) !important;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
    background: white !important;
    color: black !important;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item.p-highlight {
    background: lighten($purple, 15%) !important;
    color: white !important;
}

// Dialog
body .p-dialog .p-dialog-header {
    background: $dark-grey !important;
    border: 1px solid $dark-grey !important;

    & > .p-dialog-title {
        font-size: 14px !important;
        font-weight: bold !important;
    }

    & .p-dialog-header-close-icon {
        font-weight: bold !important;
    }
}

// Dropdowns
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    background: lighten($purple, 15%) !important;
}

// Multi-select-dropdown
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: lighten($purple, 15%) !important;
}

.p-multiselect {
    max-width: 100% !important;
}

body .p-component,
body .p-component .p-component,
body .p-component input,
body .p-component select,
body .p-component textarea,
body .p-component button {
    font-family: 'Noto Sans', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    text-decoration: none !important;
    font-size: 1em !important;
}

.p-dropdown-label.p-inputtext {
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 6px 12px;
    transition: border-color 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 6px 12px;
}

input.p-inputtext.p-component {
    padding: 6px 12px !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    padding: 6px 12px;
}

.p-autocomplete .p-autocomplete-loader {
    padding: 6px 12px;
}

.p-datepicker table td > span.p-highlight {
    background: $active-color;
}

.p-paginator-rpp-options.p-dropdown.p-component {
    height: unset;
    border: unset;
}

div.p-dialog.p-component {
    border: 0px !important;
    border-radius: 3px !important;
}

span.p-button-icon.p-button-icon-left.ng-star-inserted.pi.pi-pencil {
    color: white !important;
}

// toggle button border
p-togglebutton > div {
    border-color: #3a5169 !important;
}

// checkbox
.p-checkbox .p-checkbox-box.p-highlight {
    border: 1px solid $purple !important;
    background-color: $purple !important;
    color: $white !important;
}

// radiobutton
.p-radiobutton .p-radiobutton-box.p-highlight {
    border: 1px solid $purple !important;
    background-color: $purple !important;
    color: $white !important;
}

// calendar
.p-datepicker-calendar span {
    font-size: 14px !important;
}

// multi dropdown input
.p-autocomplete .p-autocomplete-multiple-container {
    padding: 6px 12px !important;
    gap: 6px;
}

.p-datatable .p-datatable-tbody > tr {
    outline: $purple !important;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
    border-color: $purple !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(115 80 147 / 60%) !important;
}

// box shadow sortable column and paging
.p-datatable .p-sortable-column:focus, .p-link:focus {
    box-shadow: none !important;
}

.p-dropdown .ng-trigger.ng-trigger-overlayAnimation.p-dropdown-panel.p-component {
    left: 0 !important;
}
